// @ts-nocheck
import React from "react";
import CommonModal from "../shared_comp/Modal";
import { usePrivacy } from "../../hooks/useLanguage";

const Privacy = () => {
  const { isPrivacy, onPrivacyOpen, onPrivacyClose } = usePrivacy();
  return (
    <CommonModal
      isOpen={isPrivacy}
      onOpen={onPrivacyOpen}
      onClose={onPrivacyClose}
      onRequestClose={true}
      label="Privacy & policy"
      onClick={() => {}}
      isFull
    >
      <div className="d-flex flex-column gap-2">
        <small
          style={{
            letterSpacing: ".02rem",
            lineHeight: "1.7rem",
            textAlign: "justify",
            fontSize: "13px",
          }}
          className="text-black text-opacity-75 pb-4"
        >
          
        </small>
      </div>
    </CommonModal>
  );
};

export default Privacy;
