// @ts-nocheck
import React, { useCallback } from "react";
import CommonModal from "../shared_comp/Modal";

import { useLottery } from "../../hooks/useLanguage";
import { useNavigate } from "react-router-dom";

import { GiPodiumWinner } from "react-icons/gi";
import { FaRankingStar, FaGift } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const Lottery = () => {
  const { t } = useTranslation();
  const { isLotteryOpen, onLotteryOpen, onLotteryClose } = useLottery();

  const navigate = useNavigate();

  const onNavigate = useCallback(
    (url) => {
      navigate(url);
      onLotteryClose();
    },
    [navigate, onLotteryClose]
  );
  return (
    <CommonModal
      isOpen={isLotteryOpen}
      onOpen={onLotteryOpen}
      onClose={onLotteryClose}
      onRequestClose={true}
      label="Winner & Lucky Number"
      onClick={() => {}}
      isFull={false}
    >
      <div
        style={{ fontSize: "16px" }}
        className="d-flex flex-column gap-2 mt-2"
      >
        <div role="button" onClick={() => onNavigate("/winner-list")}>
          <GiPodiumWinner
            style={{ margin: "0rem 1rem .8rem 0rem" }}
            size={25}
          />
          {t("winnerListHistory.title")}
        </div>
        <div role="button" onClick={() => onNavigate("/win-number")}>
          <FaRankingStar size={25} style={{ margin: "0rem 1rem .7rem 0rem" }} />
          {t("winnerListHistory.lucky_number")}
        </div>
      </div>
    </CommonModal>
  );
};

export default Lottery;
